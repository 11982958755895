.info .btn {
    background: #F8F8F8;
    color: #707070;;
}

.info .active-btn {
    background: #fff;
    color: #3B86FF;
    position: relative;
}

.info .active-btn:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 6px;
    background: #fff;
}

.info .content {
    display: none;
}

.info .active-content {
    display: flex;
    flex-direction: column;
}

/* range slider */
.field {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.field input {
    -webkit-appearance: none;
    height: 3px;
    width: 100%;
    background: #3B86FF;
    border-radius: 5px;
    outline: none;
    border: none;  
}

.field input::-webkit-slider-thumb {
    /* -webkit-appearance: none; */
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 3;
    position: relative;
}
